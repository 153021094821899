export const userDefinedHandlersAndOpts = [
  "getAdditionalEditAnnotationComps",
  "getAdditionalCreateOpts",
  "getCustomAutoAnnotateList",
  "allowPartsToOverlapSelf",
  "allPartTags",
  "editTagsLink",
  "readOnly",
  "shouldAutosave",
  "hideSingleImport",
  "beforeAnnotationCreate",
  "disableSetReadOnly",
  "showReadOnly",
  "showCircularity",
  "showAvailability",
  "showGCContentByDefault",
  "GCDecimalDigits",
  "onlyShowLabelsThatDoNotFit",
  "fullscreenMode",
  "maxAnnotationsToDisplay",
  "onNew",
  "onImport",
  "onSave",
  "onSaveAs",
  "alwaysAllowSave",
  "generatePng",
  "onRename",
  "getVersionList",
  "getSequenceAtVersion",
  "onDuplicate",
  "onSelectionOrCaretChanged",
  "beforeSequenceInsertOrDelete",
  "enzymeManageOverride",
  "enzymeGroupsOverride",
  "additionalEnzymes",
  "onDelete",
  "onCopy",
  "autoAnnotateFeatures",
  "autoAnnotateParts",
  "autoAnnotatePrimers",
  "onCreateNewFromSubsequence",
  "onPaste",
  "menuFilter",
  "externalLabels"
];
